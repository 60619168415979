
module.exports =
  layout: 'promo'
  metaInfo:
    title: 'Why HoneyMoney works' #70 characters
    meta: [
      { name: 'description', content: 'HoneyMoney.io — personal finance tool for savers' }
    ]
  # components:
  #   sign_up_hero: require('promo/en/layouts/sign_up_hero.vue').default
  #   hm_slideshow: require('@/components/hm_slideshow.vue').default
  #   io_form:  require('@/components/io_form.vue').default
  # data: ->
  #   menu_visible: false
